<template>

  <!-- If the user is NOT logged in, just show the brand image and login button -->
  <nav v-if="!isLoggedIn" class="navbar fixed-top bg-light">
    <!--brand-->
    <router-link @click="collapseOpenMenu()" class="navbar-brand mr-auto" to="/dashboard">
      <img :src="`${publicPath}logo-with-text.svg`" alt="Logo" height="45" class="logo-image">
    </router-link>

    <!--login-->
    <router-link class="btn btn-primary btn-login" to="/log-in">
      log in
    </router-link>
  </nav>


  <!-- If the user IS logged in, show everything -->
  <nav v-else class="navbar fixed-top navbar-expand-lg bg-light">

    <!--brand-->
    <router-link @click="collapseOpenMenu()" class="navbar-brand mr-auto" to="/dashboard">
      <img :src="`${publicPath}logo-with-text.svg`" alt="Logo" height="45" class="logo-image">
    </router-link>

    <!--user-->
    <div class="nav-item dropdown-center user-dropdown text-center text-lg-start">
      <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img v-if="user.picture_url" :src="user.picture_url" height="20" class="profile-pic" referrerPolicy="no-referrer">
        <i v-else class="fa fa-user-circle"></i> {{user.username}}
      </a>
      <ul class="dropdown-menu">
        <li><router-link @click="collapseOpenMenu()" class="dropdown-item" to="/profile"><i class="fa fa-user-circle"></i> profile</router-link></li>
        <li><router-link @click="collapseOpenMenu()" class="dropdown-item" to="/settings"><i class="fa fa-cog"></i> settings</router-link></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" @click="$emit('log-out-user')" href="#"><i class="fa fa-sign-out"></i> log out</a></li>
      </ul>
    </div>


    <!--menu-->
    <button class="navbar-toggler collapsed" ref="navtoggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">
        <span v-if="navbarTogglerPillNumber > 0" class="badge rounded-pill bg-danger">{{navbarTogglerPillNumber}}</span>
      </span>
    </button>

    <!--nav links-->
    <div class="collapse navbar-collapse mx-auto" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/dashboard" @click="collapseOpenMenu()" :class="{ active: isActive('dashboard'), disabled: !isLoggedIn }" class="nav-link">Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/checklist" @click="collapseOpenMenu()" :class="{ active: isActive('checklist'), disabled: !isLoggedIn }" class="nav-link">Checklists</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/viewing-options" @click="collapseOpenMenu()" :class="{ active: isActive('viewing-options'), disabled: !isLoggedIn }" class="nav-link">Viewing Options</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/friends" @click="collapseOpenMenu()" :class="{ active: isActive('friends'), disabled: !isLoggedIn }" class="nav-link">
            Friends<span v-if="friendInvitePillNumber > 0" class="badge rounded-pill bg-danger">{{friendInvitePillNumber}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" @click="collapseOpenMenu()" :class="{ active: isActive('about') }" class="nav-link">About</router-link>
        </li>
        <li v-if="isAdmin" class="nav-item">
          <router-link to="/admin" @click="collapseOpenMenu()" :class="{ active: isActive('admin'), disabled: !isAdmin }" class="nav-link admin-colors"><i class="fa fa-wrench"></i>Admin</router-link>
        </li>
      </ul>

      <!--search-->
      <div class="nav-item">
        <form class="form-search">
          <div class="input-group">

            <!-- display disabled one if user is not logged in-->
            <input class="form-control" type="text" placeholder="search" v-model="searchText" />

            <div class="btn-group search-button">
              <input class="btn btn-outline-secondary btn-sm" :class="{disabled: !isLoggedIn}" type="submit" :value="`${searchType}`" @click.prevent="onSearch"/>
              <button type="button" class="btn btn-outline-secondary dropdown-toggle btn-sm dropdown-toggle-split" :class="{disabled: !isLoggedIn}" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>

              <ul class="dropdown-menu search-dropdown dropdown-menu-end">
                <li><a class="dropdown-item" href="#" @click.prevent="this.searchType = 'Titles'">Film Titles</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="this.searchType = 'Overviews'">Film Overviews</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="this.searchType = 'Nominees'">Nominees</a></li>
              </ul>
            </div>
          </div>
        </form>
      </div>

    </div> <!--navbar collapse-->
  </nav>
</template>


<script>
import {searchStore, userStore} from "@/userStore";
import {EventBus} from "@/eventBus";

export default {
  name: "NavBar",
  computed: {
    isLoggedIn() {
      return userStore.isLoggedIn();
    },
    isAdmin() {
      return userStore.isAdmin();
    },
    isTester() {
      return userStore.isTester();
    },
    navbarTogglerPillNumber() {
      return this.friendInvitePillNumber; // more to be added if anything other than friend invites is added
    },
    friendInvitePillNumber() {
      return ( userStore.user?.friendInviteCount ? userStore.user?.friendInviteCount : 0 );
    },
    user() {
      return userStore.user;
    }
  },

  emits: ['log-out-user'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      searchType: "Titles",
      searchText: ''
    }
  },
  // mounted() {
  //   document.body.addEventListener('click', this.collapseOpenMenu);
  // },
  // beforeUnmount() {
  //   document.body.removeEventListener('click', this.collapseOpenMenu);
  // },
  methods: {
    isActive(_page) {
      return this.$route.path.substring(1).startsWith(_page);
    },

    collapseOpenMenu() {
      // no navtoggle to collapse
      if ( !this.$refs.navtoggle?.classList ) {
        return;
      }
      // menu already collapsed
      if ( this.$refs.navtoggle?.classList?.contains('collapsed') ) {
        return;
      }
      // ok, collapse
      this.$refs.navtoggle.click();
    },

    async onSearch() {
      console.log(`searching ${this.searchType}...`);
      this.searchText = this.searchText.trim().toLowerCase();
      if (this.searchText === '') {
        return;
      }

      this.$gtag.event('search', {
        type: this.searchType,
        text: this.searchText
      });

      this.collapseOpenMenu();

      // save search info in our store
      searchStore.type = this.searchType;
      searchStore.text = this.searchText;

      // navigate to the results page and emit an event
      this.$router.push({name: 'SearchResultsPage'});
      EventBus.emit('search-term-updated', this.searchType, this.searchText);
    }
  }

}

</script>


<style scoped>

  .btn-login {
    margin-right: 10px;
    margin-left: 10px;
  }

  .navbar {

    /*
    opacity: 80%;
     */

    border-bottom-style: solid;
    border-bottom-color: var(--bs-secondary);
    border-bottom-width: 1px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .navbar-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-toggler {
    margin-right: 10px;
    z-index: 1;
  }
  .nav-item {
    .badge {
      font-size: 50%;
      vertical-align: top;
    }
    .active {
      font-weight: 700;
    }
  }
  .navbar-toggler-icon {
    .badge {
      font-size: 50%;
      vertical-align: top;
      margin-right: -45px;
      margin-top: -10px;
    }
  }

  .admin-colors {
    color: crimson!important;
  }

  .form-search {
    margin-left: 10px;
    margin-right: 10px;


    .search-button {
      input {
        border-right: none;
      }
      .btn-sm {
        font-size: smaller;

      }
      .dropdown-toggle-split {
        border-left: none;

      }
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .form-control {
      border-color: var(--bs-btn-border-color);
      border-right: none;

      margin-top: 3px;
      margin-bottom: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .navbar-brand {
    padding: 0px 0px 0px 0px;
    margin-right: 10px;
    margin-left: 10px;

    .logo-image {
      float: left;
    }

    .text-my {
      font-weight: 300;
    }
    .text-oscar {
      font-weight: 600;
    }
    .text-list {
      font-weight: 300;
    }
  }

  .user-dropdown {
    font-size: smaller;
    width: 170px;
    white-space: nowrap;

    a {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .profile-pic {
    border-radius: 50%;
  }

</style>